<template>
  <div class="psw-main">
    <div class="child">
      <div class="tips-txt">
        <p>本网站仅供内部测试使用，需进行二次身份验证后进入</p>
        <i
          >学员如需学习，请点击前往：<a href="https://hxclass.cn/"
            >华夏云课堂</a
          ></i
        >
      </div>
      <div>
        <a-input class="input" v-model="psw" placeholder="请输入安全密钥" />
      </div>
      <a-button class="btn" @click="onCheck">点击验证</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      psw: "",
      errorNum: 1,
      oldPsw: "xyymyyhtlhtl1305",
      address:'https://hxclass.cn/',
    };
  },
  // 事件处理器
  methods: {
    onCheck() {
      if(!this.psw){
        this.$message.warning("请输入安全密钥");
        return
      } else if (this.oldPsw == this.psw) {
        localStorage.setItem("isCertified", true);
        this.$router.push({
          path: "/",
        });
      } else if (this.errorNum >= 3) {
        window.open(this.address,'_self');
      } else {
        this.psw = "";
        this.$message.error("认证失败");
      }
      this.errorNum++;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.psw-main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .child {
    max-width: 600px;
    text-align: center;
    background-color: @theme;
    padding: 30px;
    border-radius: 20px;
    .tips-img {
      width: 100%;
    }
    .input {
      width: 400px;
    }
    .tips-txt {
      color: #ffffff;
      margin-bottom: 30px;
      text-align: left;
      p {
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 28px;
      }
      i {
        font-size: 18px;
        font-style: normal;
        a {
          color: red;
          text-decoration: underline;
        }
      }
    }
    .btn {
      margin-top: 10px;
    }
  }
}
</style>
